<template>
  <div>
    <div class="infinite-list" style="width: 100%">
      <div class="content-box mt-3 mb-3 pr">
        <div class="back-btn tac cp" @click="goback()">
          <i class="iconfont icon-fanhui"></i>
          <div class="mt-2">返回</div>
        </div>
        <div class="bs p-3" style="width: 100%;background: #ffffff">
          <div class="pl-5 pr-5">
            <div class="uf uf-ac">
              <div class="sign"></div>
              <div class="f18 ml-2 ">购买信息</div>
            </div>
            <div class="border-all mt-3 ">
              <el-table
                  :data="dataList"
                  size="small"
                  :header-cell-style="{background:'#eee',color:'#606266'}"
                  style="width: 100%;">
                <el-table-column
                    prop="serviceName"
                    align="center"
                    label="服务名称">
                </el-table-column>
                <el-table-column
                    prop="difference"
                    align="center"
                    label="服务属性">
                </el-table-column>
                <el-table-column
                    prop="quantity"
                    align="center"
                    width="90"
                    label="购买数量">
                </el-table-column>
                <el-table-column
                    prop="price"
                    align="center"
                    width="90"
                    label="购买单价">
                </el-table-column>
                <el-table-column
                    prop="discountProductPrice"
                    align="center"
                    width="90"
                    label="折扣单价">
                </el-table-column>
                <el-table-column
                    prop="discount"
                    align="center"
                    width="120"
                    label="可用折扣次数">
                </el-table-column>
                <el-table-column
                    prop="totalSum"
                    align="center"
                    width="100"
                    label="总金额（元）">
                </el-table-column>
              </el-table>
            </div>
            <div class="border-bottom" style="height: 50px;"></div>
            <div class="uf uf-ac mt-3">
              <div class="sign"></div>
              <div class="f18 ml-2 ">订单联系人</div>
              <div class="uf uf-ac ml-5 c-base">
                <i class="el-icon-warning "></i>
                <div class="ml-1 f14 "> 订单提交后，平台专业人员会与您沟通，请保持电话畅通</div>
              </div>
            </div>
            <!--          表单-->
            <el-form class="mt-5 uf uf-ac fwrap-w" :model="dataForm" :rules="rules" ref="dataForm" label-width="100px">
              <el-form-item label="公司" prop="company" v-if="this.info.length>0">
                <el-select v-model="dataForm.company" placeholder="请选择公司">
                  <el-option
                      v-for="(item,index) in this.info"
                      :key="index"
                      :label="item.enterpriseName"
                      :value="item.enterpriseName">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" prop="contacts">
                <el-input v-model="dataForm.contacts"
                          placeholder="请输入您的姓名"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="mobile">
                <el-input v-model="dataForm.mobile"
                          :maxlength="11"
                          placeholder="请输入您的联系电话"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="dataForm.email"
                          clearable
                          placeholder="请输入您的邮箱"></el-input>
              </el-form-item>
              <el-form-item class="wi100" label="订单备注" prop="notes">
                <el-input type="textarea" v-model="dataForm.notes"
                          placeholder="输入订单备注"></el-input>
              </el-form-item>
            </el-form>
            <el-divider></el-divider>

            <div>
              <div class="uf uf-ac mt-3" style="margin-bottom: 30px">
                <div class="sign"></div>
                <span class="f18 ml-2 ">附件上传：</span>
                <div>
                  <el-upload
                      class="upload-demo"
                      action="#"
                      :on-change="handleUpload"
                      :http-request="httpRequest"
                      :on-remove="handleRemove"
                      :before-upload="handleBeforeUpload"
                      :limit="1"
                      :on-exceed="handleExceed"
                      :file-list="fileList"
                      ref="myupload">
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </div>
                <div style="color: #7e848d;font-size: 13px;margin-left: 20px">
                  *根据需要，上传必要文件
                </div>
              </div>
              <div v-if="dataForm.templateFilePath">
                <a :href="$cons.SERVER_PATH + this.dataForm.templateFilePath" style="margin: 6px;"><img
                    src="~@/assets/img/ZIP.png" style="width: 20px; height: 20px; vertical-align: middle">模板文件</a>
                <div class="uf uf-ac ml-2 c-base" style="margin-top: 5px">
                  <i class="el-icon-warning "></i>
                  <div class="ml-1 f14 "> 下载模板文件查看需要上传材料，您也可在【我的订单】-【查看详情】里面进行上传</div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
            <!--            发票信息-->
            <div>
              <div class="uf uf-ac mt-3" style="margin-bottom: 30px">
                <div class="sign"></div>
                <span class="f18 ml-2 ">发票信息：</span>
              </div>
              <div class="uf uf-ac">
                <div v-if="dataForm.invoiceApplyStatus === '1'">不开发票</div>
                <div class="uf uf-ac" v-else>
                  <div class="uf uf-ac" v-if="invoiceInfo.invoiceType === '1'">
                    <div class="mr-1">电子普通发票</div>
                    <el-tooltip class="item" effect="dark" content="电子普通发票与纸质发票具有同等法律效力，可支持报销入账、商品售后凭证。"
                                placement="top-start">
                      <i class="el-icon-warning-outline cp"></i>
                    </el-tooltip>
                  </div>
                  <div class="uf uf-ac" v-else>
                    <div class="mr-1">增值税专用发票</div>
                  </div>
                  <div class="ml-3" v-if="invoiceInfo.invoiceType === '1'">
                    {{ invoiceInfo.invoiceHeaderType === 'OWN' ? '个人' : '单位' }}
                  </div>
                  <div class="ml-3">{{ invoiceInfo.headerName }}</div>
                </div>
                <div class="ml-3">
                  <el-button type="text" size="mini" style="padding: 0;" @click="invoiceUpdate">修改</el-button>
                  <el-button type="text" size="mini" style="padding: 0;" @click="invoiceClose"
                             v-if="dataForm.invoiceApplyStatus === '2'">不开发票
                  </el-button>
                </div>
              </div>
            </div>
            <div class="uf uf-ac uf-pj bot">
              <div class="uf uf-ac uf-f1">
                <el-checkbox v-model="checked">
                  <p class="f14"> 我已阅读 <span>
                  <a href="#" class="c-base" style="text-decoration: none;" @click="payAgreement">《用户协议》</a>
                </span>
                    ,且同意该合同内容 </p>
                </el-checkbox>
              </div>
              <p class="pr-5 mr-5">订单总金额：<span class="fwb c-red f20 ml-3">
                ￥{{ dataForm.totalSum }}
              </span></p>
              <el-button
                  :loading="payButton"
                  type="primary"
                  class="ml-5 pl-5"
                  :disabled="!checked"
                  @click="payAck">
                提交订单
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pay-agreement v-if="payAgreementVisible" ref="payAgreement" @closeCallback="closeCallback"/>
    <pay v-if="payVisible" ref="pay" @payCloseCallback="payCloseCallback" @paymentSuccessful="paymentSuccessful"/>
    <!--    发票信息编辑-->
    <invoiceEditing v-if="invoiceEditingVisible" ref="invoiceEditingRef"
                    @invoiceCallBack="invoiceCallBack"></invoiceEditing>
  </div>
</template>

<script>
import PayAgreement from './pay-agreement'
import Pay from './pay'
import invoiceEditing from './component/invoice-editing'
import {isMobile, isEmail} from '../../utils/validate'
import {checkIsNull} from '../../utils'

export default {
  components: {
    Pay,
    PayAgreement,
    invoiceEditing
  },
  data() {
    const checkMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号码'))
      } else if (!isMobile(value)) {
        return callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
    const checkEmail = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入邮箱'))
      } else if (!isEmail(value)) {
        return callback(new Error('邮箱格式不正确'))
      } else {
        callback()
      }
    }
    return {
      //路由传参 (对向形式--购买商品的详细信息detail)
      data: '',
      dataForm: {
        contacts: '',
        company: '',
        mobile: '',
        email: '',
        notes: '',
        phaseOne: 0,
        phaseTwo: 0,
        phaseThree: 0,
        phaseFour: 0,
        templateFilePath: '',
        //  是否开具发票
        invoiceApplyStatus: '1'
      },
      fileList: [],
      payButton: false,
      dataList: [],
      payAgreementVisible: false,
      payVisible: false,
      // 发票编辑弹框显隐
      invoiceEditingVisible: false,
      //获取商品价格列表
      priceList: '',
      //支付按钮状态
      checked: false,
      //表单验证规则
      rules: {
        contacts: [
          {required: true, message: '请输入您的姓名', trigger: 'blur'},
          {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
        ],
        mobile: [
          {required: true, validator: checkMobile, trigger: 'blur'}
        ],
        company: [
          {required: true, message: '选择购买方', trigger: 'blur'}
        ],
        email: [
          {required: true, validator: checkEmail, trigger: 'blur'}
        ]
      },
      // 支付方式，1：全款，2：分期
      radio: '1',
      // 分期数
      phase: 2,
      id: '',
      //  开票信息
      invoiceInfo: {}
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.user.name
      }
    },
    mobile: {
      get() {
        return this.$store.state.user.mobile
      }
    },
    email: {
      get() {
        return this.$store.state.user.email
      }
    },
    info: {
      get() {
        return this.$store.state.user.info
      }
    },
    userId: {
      get() {
        return this.$store.state.user.id
      }
    }
  },
  created() {
    // 不能刷新 后期改
    let info = this.$route.params.info ?  this.$route.params.info : JSON.parse(localStorage.getItem('payMentInfo'))
    //   this.$route.params.info
    //订单联系人赋初值
    this.dataForm = {
      ...info,
      phaseOne: this.dataForm.phaseOne,
      phaseTwo: this.dataForm.phaseTwo,
      phaseThree: this.dataForm.phaseThree,
      phaseFour: this.dataForm.phaseFour,
      payType: 'offline',
      invoiceApplyStatus: '1'
    }
    // contacts: this.name, mobile: this.mobile, email: this.email,
    this.dataList.push(this.dataForm)
    this.$forceUpdate()
  },
  methods: {
    goback() {
      // this.$router.go(-1)
      let itemCodeType = 'pay'
      this.$router.push({name: 'service', query: {id: this.dataForm.serviceId, itemCodeType: itemCodeType}})
    },
    payAgreement() {
      this.payAgreementVisible = true
      this.$nextTick(() => {
        this.$refs['payAgreement'].init()
      })
    },
    payAck() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (!checkIsNull(this.info.enterpriseName)) {
            this.dataForm.company = this.info.enterpriseName
          }
          if (checkIsNull(this.dataForm.file)) {
            this.$http({
              url: this.$http.adornUrl(this.$api.ORDER.SAVE),
              method: 'post',
              data: this.$http.adornData(this.dataForm)
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.payButton = true
                this.payVisible = true
                this.$nextTick(() => {
                  this.$refs['pay'].init(data.info)
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          } else {
            let formData = new FormData()
            formData.append('file', this.dataForm.file)
            formData.append('userId', this.userId)
            this.$http({
              url: this.$http.adornUrl(this.$api.UPLOAD.ORDER_FILE),
              method: 'post',
              data: formData
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.orderFile = this.$deepClone(data.path)
                this.dataForm.id = this.$deepClone(data.id)
                this.$http({
                  url: this.$http.adornUrl(this.$api.ORDER.SAVE),
                  method: 'post',
                  data: this.$http.adornData(this.dataForm)
                }).then(({data}) => {
                  if (data && data.code === 0) {
                    this.payButton = true
                    this.payVisible = true
                    this.$nextTick(() => {
                      this.$refs['pay'].init(data.info)
                    })
                  } else {
                    this.$message.error(data.msg)
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        }
      })
    },
    closeCallback(refresh) {
      this.payAgreementVisible = false
      if (refresh) {
        this.checked = true
      }
    },
    payCloseCallback(refresh) {
      this.payVisible = false
      this.payButton = false
      if (refresh) {
        this.$router.push('my-order')
      }
    },
    // 发票编辑弹框
    invoiceUpdate() {
      this.invoiceEditingVisible = true
      this.$nextTick(() => {
        this.$refs.invoiceEditingRef.init(this.invoiceInfo)
      })
    },
    // 不开发票
    invoiceClose() {
      this.dataForm.invoiceApplyStatus = '1'
      this.$delete(this.dataForm, 'serviceOrderInvoiceEntity')
    },
    // 发票编辑回调
    invoiceCallBack(data) {
      this.invoiceEditingVisible = false
      if (data && data.headerName) {
        this.dataForm.invoiceApplyStatus = '2'
        this.dataForm.serviceOrderInvoiceEntity = data || {}
        this.invoiceInfo = this.$deepClone(data)
      }
    },
    //跳转支付成功页面
    paymentSuccessful(orderNo, totalSum) {
      this.payVisible = false
      this.payButton = false
      //延迟跳转
      this.$router.push({
        name: 'pay-successful',
        params: {
          orderNo: orderNo,
          totalSum: totalSum,
          serviceName: this.dataForm.serviceName
        }
      })
    },
    // 获取支付期数
    getPrice() {
      if (this.radio === '1') {
        this.dataForm.phaseOne = 0
        this.dataForm.phaseTwo = 0
        this.dataForm.phaseThree = 0
        this.dataForm.phaseFour = 0
      }
      if (this.radio === '2') {
        this.getPriceByPhase()
      }
    },
    getPriceByPhase() {
      let phasePrice = parseInt(this.dataForm.totalSum / parseInt(this.phase))
      this.dataForm.phaseOne = phasePrice + this.dataForm.totalSum % parseInt(this.phase)
      this.dataForm.phaseTwo = phasePrice
      this.dataForm.phaseThree = this.phase >= 3 ? phasePrice : 0
      this.dataForm.phaseFour = this.phase === 4 ? phasePrice : 0
    },
    /**
     * 上传license成功回调
     */
    handleUpload(response = {}, file) {
      this.fileList = file
    },
    httpRequest(data) {
      this.dataForm.file = data.file
    },
    handleRemove(file, fileList) {
      this.dataForm.file = null
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件')
    },
    handleBeforeUpload(file) {
      const typeList = ['zip', 'rar']
      const fileName = file.name
      const extension = fileName.substr(fileName.lastIndexOf('.') + 1)
      const isRT = typeList.includes(extension)
      if (!isRT) {
        this.$message.error('请上传压缩文件, 格式为zip或rar，模板格式查看【模板文件】')
        return false
      }
    }
  }
}

</script>

<style scoped lang="scss">
.infinite-list {
  padding-top: 90px;
}
.content-box {
  width: 1325px;
  margin: 20px auto;
  .bs {
    border-radius: 0 10px 10px;
  }
}
.bot {
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #d0d0d0;
  margin-top: 10px;
}
.sign {
  width: 5px;
  height: 24px;
  background-color: #409eff;
}

.price-list {
  background-color: #ccc;
}

.price-list-color:hover {
  background-color: #409eff;
}
</style>
